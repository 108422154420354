.sf-header {
  display: flex;
  text-transform: lowercase;
  border-bottom: 1px solid #f0f0f0;
  height: 3rem;
  align-items: center;
  position: sticky;
  top: 0px;
  left: 0px;
  background-color: white;
  z-index: 1;
  padding: 0 3rem;

  .app-name {
    flex-grow: 1;
    font-family: 'Rajdhani', sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    height: 100%;
    position: relative;

    & > a {
      padding: 0 1rem;
      display: inline-flex;
      align-items: center;
      height: calc(100% + 1px);
    }
  }
  .session-links {
    a {
      margin-left: 1rem;
      opacity: 0.6;
    }
    a.active {
      font-weight: bold;
    }
  }
}
